import { $Values } from 'utility-types'
import { utils as circuitUtils } from '../circuits'
import { makeListSelectorItem } from '../../utils'

export const generations = Object.freeze({
  BIG_BANG: 'BB',
  NEBULA: 'NB',
  ORION: 'OR',
  UNKNOWN: 'UNKNOWN',
})

export const METER_RESOURCE_TYPES = ['ELECTRICITY', 'NATURAL_GAS']

export type FTMeterGeneration = $Values<typeof generations>
export type FTGenerationLookup = { [FTMeterGeneration]: any }

export const nonNeutalPanels = [
  'THREE_PHASE_3_WIRE_WYE',
  'THREE_PHASE_3_WIRE_DELTA',
  'THREE_PHASE_3_WIRE_CORNER_GROUNDED_DELTA',
]

export const pendingLimits: FTGenerationLookup = {
  [generations.BIG_BANG]: [48, 'hours'],
  [generations.NEBULA]: [15, 'minutes'],
  [generations.ORION]: [15, 'minutes'],
}
export const scalingFactors = {
  [generations.BIG_BANG]: [
    ['1.000', 'None'],
    ['2.308', '277V to 120V (277/480V 3-Phase Wye)'],
    ['2.892', '347V to 120V (347/600V 3-Phase Wye)'],
    ['3.880', '480V to 120V (480V 3-Phase 3-Wire Delta)'],
    ['5.000', '600V to 120V (600V 3-Phase 3-Wire Delta)'],
  ],
  [generations.NEBULA]: [
    ['1.000', 'None'],
    ['3.880', '480V to 120V (480V 3-Phase 3-Wire Delta)'],
    ['1.733', '480V to 277V (480V 3-Phase 3-Wire Delta)'],
    ['2.892', '600V to 120V (347/600V 3-Phase Wye)'],
    ['5.000', '600V to 120V (600V 3-Phase 3-Wire Delta)'],
    ['1.253', '600V to 277V (347/600V 3-Phase Wye)'],
    ['2.166', '600V to 277V (600V 3-Phase 3-Wire Delta)'],
  ],
  [generations.ORION]: [
    ['1.000', 'None'],
    ['2.892', '600V to 120V (347/120V 3-Phase, 3-Wire Wye)'],
  ],
}
// To get around Flow errors about non-string literal keys

/* eslint-disable quote-props */
const scalingFactorsById = {
  [generations.BIG_BANG]: {
    '1.000': 'None',
    '2.308': '277V to 120V (277/480V 3-Phase Wye)',
    '2.892': '347V to 120V (347/600V 3-Phase Wye)',
    '3.880': '480V to 120V (480V 3-Phase 3-Wire Delta)',
    '5.000': '600V to 120V (600V 3-Phase 3-Wire Delta)',
  },
  [generations.NEBULA]: {
    '1.000': 'None',
    '3.880': '480V to 120V (480V 3-Phase 3-Wire Delta)',
    '1.733': '480V to 277V (480V 3-Phase 3-Wire Delta)',
    '2.892': '600V to 120V (347/600V 3-Phase Wye)',
    '5.000': '600V to 120V (600V 3-Phase 3-Wire Delta)',
    '1.253': '600V to 277V (347/600V 3-Phase Wye)',
    '2.166': '600V to 277V (600V 3-Phase 3-Wire Delta)',
  },
  [generations.ORION]: {
    '1.000': 'None',
    '2.892': '600V to 120V (347/120V 3-Phase, 3-Wire Wye)',
  },
}
export const fieldNameMap = new Map<string, string>([
  ['_120_208_240V_SINGLE_PHASE', '120/208/240V'],
  ['_120_208_240V_THREE_PHASE', '120/208/240V (3-Phase 240V Circuits)'],
  ['_120_240V', '120/240V'],
  ['_208V', '208V'],
  ['_208Y_120V', '208Y/120V'],
  ['_230V', '230V'],
  ['_240V', '240V'],
  ['_400V', '400V'],
  ['_400Y_230V', '400Y/230V'],
  ['_415Y_240V', '415Y/240V'],
  ['_480V', '480V'],
  ['_480Y_277V', '480Y/277V'],
  ['_600V', '600V'],
  ['_600Y_347V', '600Y/347V'],
  ['OTHER', 'Other'],
  ['SINGLE_PHASE_3_WIRE', 'Single Phase, 3-Wire (Split Phase)'],
  ['THREE_PHASE_3_WIRE_DELTA', '3-Phase, 3-Wire Delta (No Neutral)'],
  [
    'THREE_PHASE_3_WIRE_CORNER_GROUNDED_DELTA',
    '3-Phase, 3-Wire Corner-Grounded Delta (No Neutral)',
  ],
  ['THREE_PHASE_3_WIRE_WYE', '3-Phase, 3-Wire Wye (No Neutral)'],
  ['THREE_PHASE_4_WIRE_DELTA', '3-Phase, 4-Wire Delta'],
  ['THREE_PHASE_4_WIRE_WYE', '3-Phase, 4-Wire Wye'],
])

/* eslint-disable quote-props */

/**
 * Conditional field structure for the meter configuration form.
 *
 * Dependency chain: generation: panelType: panelVoltage: voltageScalingFactor.
 *
 * - For each generation, only particular panel types are available.
 * - For each panel type, only particular panel voltages are available.
 * - For each panel voltage, only particular voltage scaling factors are
 * available.
 *
 * return {object}
 *   An object with the following keys:
 *     - panelTypes: An array of panel types.
 *     - getVoltageValues(): Gets an array of voltage values.
 *     - getVoltagesById(): Gets an Object of Voltages keyed by id.
 *     - getVoltageScalingFactorItems(): Gets an array of items suitable for use
 *       in the items prop of a ListSelector component.
 *     - getVoltageScalingFactorsById(): Gets an Object of voltage scaling
 *       factors keyed by id.
 */
export const getConditionalFields = (
  generation: FTMeterGeneration,
): Record<string, any> => {
  const dependencies: Record<string, any> = {
    [generations.BIG_BANG]: {
      SINGLE_PHASE_3_WIRE: {
        _120_240V: {
          '1.000': 'None',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      THREE_PHASE_4_WIRE_WYE: {
        _208Y_120V: {
          '1.000': 'None',
        },
        _400Y_230V: {
          '1.917': '230V to 120V',
        },
        _415Y_240V: {
          '2.000': '240V to 120V',
        },
        _480Y_277V: {
          '2.308': '277V to 120V',
        },
        _600Y_347V: {
          '2.700': '347V to 120V (Hammond)',
          '2.892': '347V to 120V (Other)',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      THREE_PHASE_3_WIRE_DELTA: {
        _208V: {
          '1.733': '208V to 120V',
        },
        _230V: {
          '1.917': '230V to 120V',
        },
        _240V: {
          '2.000': '240V to 120V',
        },
        _400V: {
          '3.333': '400V to 120V',
        },
        _480V: {
          '3.888': '480V to 120V (Jefferson)',
          '4.000': '480V to 120V (Other)',
        },
        _600V: {
          '4.730': '600V to 120V (Jefferson)',
          '5.000': '600V to 120V (Other)',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      THREE_PHASE_4_WIRE_DELTA: {
        _120_208_240V_SINGLE_PHASE: {
          '1.000': 'None',
        },
        _120_208_240V_THREE_PHASE: {
          '2.000': 'None',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      OTHER: {
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
    },
    [generations.NEBULA]: {
      SINGLE_PHASE_3_WIRE: {
        _120_240V: {
          '1.000': 'None',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      THREE_PHASE_4_WIRE_WYE: {
        _208Y_120V: {
          '1.000': 'None',
        },
        _400Y_230V: {
          '1.000': 'None',
        },
        _415Y_240V: {
          '1.000': 'None',
        },
        _480Y_277V: {
          '1.000': 'None',
        },
        _600Y_347V: {
          '1.000': 'None',
          // TODO: Hardcoding the value because backend doesn't support other values
          '1.00': 'HATCH LS120-75EN-347 347V to 120V',
          OTHER: 'Other',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      THREE_PHASE_4_WIRE_DELTA: {
        _120_208_240V_SINGLE_PHASE: {
          '1.000': 'None',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      THREE_PHASE_3_WIRE_WYE: {
        _480V: {
          '1.000': 'None',
          '1.00': 'Hammond SP150ACP (480V to 240V)',
          OTHER: 'Other',
        },
        _600V: {
          '1.000': 'None',
          '1.00': 'Hammond SP150ACP (600V to 240V)',
          OTHER: 'Other',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      THREE_PHASE_3_WIRE_DELTA: {
        _240V: {
          '1.000': 'None',
        },
        _480V: {
          '1.000': 'None',
        },
        _600V: {
          '1.000': 'None',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      THREE_PHASE_3_WIRE_CORNER_GROUNDED_DELTA: {
        _240V: {
          '1.000': 'None',
        },
        _480V: {
          '1.000': 'None',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      OTHER: {
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
    },
    [generations.ORION]: {
      THREE_PHASE_3_WIRE_WYE: {
        _480V: {
          '1.000': 'None',
        },
        _600V: {
          '1.000': 'None',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      THREE_PHASE_3_WIRE_DELTA: {
        _240V: {
          '1.000': 'None',
        },
        _480V: {
          '1.000': 'None',
        },
        _600V: {
          '1.000': 'None',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      THREE_PHASE_3_WIRE_CORNER_GROUNDED_DELTA: {
        _240V: {
          '1.000': 'None',
        },
        _480V: {
          '1.000': 'None',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      OTHER: {
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
    },
    [generations.UNKNOWN]: {
      SINGLE_PHASE_3_WIRE: {
        _120_240V: {
          '1.000': 'None',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      THREE_PHASE_4_WIRE_WYE: {
        _208Y_120V: {
          '1.000': 'None',
        },
        _400Y_230V: {
          '1.917': '230V to 120V',
        },
        _415Y_240V: {
          '2.000': '240V to 120V',
        },
        _480Y_277V: {
          '2.308': '277V to 120V',
        },
        _600Y_347V: {
          '2.700': '347V to 120V (Hammond)',
          '2.892': '347V to 120V (Other)',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      THREE_PHASE_3_WIRE_DELTA: {
        _208V: {
          '1.733': '208V to 120V',
        },
        _230V: {
          '1.917': '230V to 120V',
        },
        _240V: {
          '2.000': '240V to 120V',
        },
        _400V: {
          '3.333': '400V to 120V',
        },
        _480V: {
          '3.888': '480V to 120V (Jefferson)',
          '4.000': '480V to 120V (Other)',
        },
        _600V: {
          '4.730': '600V to 120V (Jefferson)',
          '5.000': '600V to 120V (Other)',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      THREE_PHASE_4_WIRE_DELTA: {
        _120_208_240V_SINGLE_PHASE: {
          '1.000': 'None',
        },
        _120_208_240V_THREE_PHASE: {
          '2.000': 'None',
        },
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
      OTHER: {
        OTHER: {
          '1.000': 'None',
          OTHER: 'Other',
        },
      },
    },
  }
  const panelTypes = dependencies[generation] || {}

  const getVoltagesById = (panelType: string): Record<string, any> =>
    (panelType && panelTypes[panelType]) || panelTypes.OTHER

  const getVoltageValues = (panelType: string): Array<Record<string, any>> =>
    Object.keys(getVoltagesById(panelType))

  const getVoltageScalingFactorsById = (
    panelType: string,
    panelVoltage: string,
  ): Record<string, any> => {
    const voltagesById = getVoltagesById(panelType || 'OTHER')
    return (panelVoltage && voltagesById[panelVoltage]) || voltagesById.OTHER
  }

  const getVoltageScalingFactorItems = (
    panelType: string,
    panelVoltage: string,
  ): Array<Record<string, any>> => {
    const voltageScalingFactorsById = getVoltageScalingFactorsById(
      panelType,
      panelVoltage,
    )
    const voltageScalingFactors = Object.keys(voltageScalingFactorsById)
    return voltageScalingFactors.map<string>((value) =>
      makeListSelectorItem(value, voltageScalingFactorsById[value]),
    )
  }

  return {
    panelTypes: Object.keys(panelTypes),
    getVoltageValues,
    getVoltagesById,
    getVoltageScalingFactorItems,
    getVoltageScalingFactorsById,
  }
}
export const ctTypes = {
  [generations.BIG_BANG]: [80, 300],
  [generations.NEBULA]: [15, 30, 50, 100, 120, 150, 300, 600, 1200],
  [generations.ORION]: [15, 30, 50, 100, 120, 150, 300, 600, 1200],
}
const byGeneration = {
  scalingFactors,
  scalingFactorsById,
  ctTypes,
  pendingLimits,
}

const lookupByGeneration = (
  lookup: FTGenerationLookup,
  gen,
  fallback = generations.BIG_BANG,
) => lookup[gen] || lookup[fallback]

export const getGenerationFields = (generation: FTMeterGeneration) => ({
  validCtTypes: lookupByGeneration(byGeneration.ctTypes, generation).map(
    (value) => [value, circuitUtils.formatCTType(value)],
  ),
  validScalingFactors: lookupByGeneration(
    byGeneration.scalingFactors,
    generation,
  ),
  validScalingFactorsById: lookupByGeneration(
    byGeneration.scalingFactorsById,
    generation,
  ),
  pendingLimit: lookupByGeneration(byGeneration.pendingLimits, generation),
})
const generationNames = {
  [generations.BIG_BANG]: 'Big Bang',
  [generations.NEBULA]: 'Nebula',
  [generations.ORION]: 'Orion',
  [generations.UNKNOWN]: 'Unknown',
}
export const formatGeneration = (
  generation: FTMeterGeneration = generations.UNKNOWN,
) => generationNames[generation]
export const formatGenerationAndModel = ({
  generation = generations.UNKNOWN,
  model,
}: {
  generation: FTMeterGeneration
  model: string
}): {
  generation: string
  model: string
} =>
  model === 'RPM-C-4806-2' ?
    {
      generation: 'Orion 2A',
      model: 'RPM-C-4806',
    }
  : {
      generation: generationNames[generation],
      model: model.toUpperCase() === 'UNKNOWN' ? 'Unknown' : model,
    }
