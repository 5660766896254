import { withFormik } from 'formik'
import * as Yup from 'yup'
import Form from './Form'
import {
  channelListToMap,
  channelMapToCircuitConfigurations,
  channelMapToCircuitsMeta,
  channelMapFlatten,
} from '../../ducks/meters/config'
import {
  formatNumberToDecimals,
  makeListSelectorItem,
  valueOrDefault,
} from '../../utils'
import { breakerNumbers as voltageTapOptions } from '../../ducks/circuits'
import { generations, nonNeutalPanels } from '../../ducks/meters/generation'

const getVoltageTapPhases = (isNebulaOrion) =>
  isNebulaOrion ? ['A', 'B', 'C'] : ['A']

const phases = [
  {
    name: 'A',
    value: 'Phase 1',
  },
  {
    name: 'B',
    value: 'Phase 2',
  },
  {
    name: 'C',
    value: 'Phase 3',
  },
]
const phaseOptions = phases.map((phase) =>
  makeListSelectorItem(phase.value, phase.name),
)
const phaseCorrectionAngleYup = Yup.number()
  .min(-90, 'Must be at least -90')
  .max(90, 'Must be less than 90')
const validationSchema = Yup.object().shape({
  voltageScalingFactorCustom: Yup.number()
    .min(1, 'must be at least 1')
    .max(9.999, 'must be less than 10'),
  phaseCorrectionAngleL1: phaseCorrectionAngleYup,
  phaseCorrectionAngleL2: phaseCorrectionAngleYup,
  phaseCorrectionAngleL3: phaseCorrectionAngleYup,
})
export default withFormik({
  validationSchema,

  validate(values, props) {
    const {
      panel,
      voltageScalingFactorL1,
      voltageScalingFactorL2,
      voltageScalingFactorL3,
    } = values
    const {
      meter: { model },
    } = props
    const { type: panelType, voltage: panelVoltage } = panel || {}
    const vsfValues = [
      {
        name: 'voltageScalingFactorL1',
        value: Number(voltageScalingFactorL1),
      },
      {
        name: 'voltageScalingFactorL2',
        value: Number(voltageScalingFactorL2),
      },
      {
        name: 'voltageScalingFactorL3',
        value: Number(voltageScalingFactorL3),
      },
    ]
    const errors = {}

    // Special case as per https://redaptiveinc.atlassian.net/browse/GRN-847
    if (
      ['RPM-C-4803', 'RPM-C-4805', 'RPM-C-4807', 'RPM-C-4808'].includes(
        model,
      ) &&
      panelType === 'THREE_PHASE_4_WIRE_DELTA' &&
      panelVoltage === '_120_208_240V_SINGLE_PHASE'
    ) {
      vsfValues.forEach((item) => {
        if (item.value < 0.1 || item.value > 6.0) {
          errors[item.name] = 'Must be within range 0.1 - 6.0'
        } else if (!item.value) {
          errors[item.name] = 'This field is required'
        }
      })
    } else if (!panelType && !panelVoltage) {
      // If Panel is not selected allow the maximum range of values
      vsfValues.forEach((item) => {
        if (item.value < 0.1 || item.value > 6.0) {
          errors[item.name] = 'Must be within range 0.1 - 6.0'
        } else if (!item.value) {
          errors[item.name] = 'This field is required'
        }
      })
    } else {
      // This is the default range for all the other cases
      vsfValues.forEach((item) => {
        if (item.value < 1.0 || item.value > 6.0) {
          errors[item.name] = 'Must be within range 1.0 - 6.0'
        } else if (!item.value) {
          errors[item.name] = 'This field is required'
        }
      })
    }

    return errors
  },

  handleSubmit(values, formikBag) {
    const {
      props: { submitAction, meter, stepDownTransformers },
    } = formikBag
    const { generation, id: meterId, isNebula, isOrion, panelType } = meter
    const {
      channels: channelMap,
      voltageScalingFactor,
      voltageScalingFactorCustom,
      voltageScalingFactorL1,
      voltageScalingFactorL2,
      voltageScalingFactorL3,
      vtapL1PhaseA,
      vtapL2PhaseB,
      vtapL3PhaseC,
      phaseCorrectionAngleL1,
      phaseCorrectionAngleL2,
      phaseCorrectionAngleL3,
      panelId,
      ...submissionRaw
    } = values

    const selectedStepDownTransformer = stepDownTransformers.find(
      (transformer) => transformer.name === values.stepDownTransformerName,
    )
    const stepdownTransformerId =
      selectedStepDownTransformer ? selectedStepDownTransformer.id : null
    const isNoNeutralNebulaPanel =
      nonNeutalPanels.includes(panelType) && isNebula
    const voltageScalingFactors =
      isOrion || isNebula ?
        {
          phase1: voltageScalingFactorL1,
          phase2: voltageScalingFactorL2,
          phase3: voltageScalingFactorL3,
        }
      : undefined
    const phaseCorrectionAngles =
      isOrion || isNoNeutralNebulaPanel ?
        {
          phase1: phaseCorrectionAngleL1,
          phase2: phaseCorrectionAngleL2,
          phase3: phaseCorrectionAngleL3,
        }
      : undefined
    const powerSource = {
      panelId: panelId || '',
      vtapL1PhaseA: vtapL1PhaseA || '',
      vtapL2PhaseB:
        vtapL2PhaseB || (generation === generations.BIG_BANG ? undefined : ''),
      vtapL3PhaseC:
        vtapL3PhaseC || (generation === generations.BIG_BANG ? undefined : ''),
      highLeg: values.highLeg,
      stepdownTransformerId,
    }
    const meterMetaFields = {
      meter: {
        id: meterId,
        powerSource,
      },
    }
    const voltageScalingFactorComputed =
      (
        voltageScalingFactor === 'OTHER' ||
        voltageScalingFactor !== voltageScalingFactorCustom
      ) ?
        voltageScalingFactorCustom
      : voltageScalingFactor
    submitAction(
      {
        meta: {
          circuits: channelMapToCircuitsMeta(channelMap),
          ...meterMetaFields,
        },
        meterConfig: {
          circuitConfigurations: channelMapToCircuitConfigurations(channelMap),
          voltageScalingFactor:
            isOrion ? undefined : voltageScalingFactorComputed,
          voltageScalingFactors,
          phaseCorrectionAngles,
        },
        ...submissionRaw,
        id: meterId,
        circuitsFlattened: channelMapFlatten(channelMap),
      },
      formikBag,
    )
  },

  mapPropsToValues(props) {
    const {
      channelList,
      voltageScalingFactor,
      voltageScalingFactors,
      phaseCorrectionAngles,
      powerSource,
      meter: { isOrion },
      stepDownTransformers,
    } = props
    const { panel, vtapL1PhaseA, vtapL2PhaseB, vtapL3PhaseC } =
      powerSource || {}
    const {
      phase1 = isOrion ? 1.7321 : 1.0,
      phase2 = isOrion ? 1.7321 : 1.0,
      phase3 = 1.0,
    } = voltageScalingFactors || {}
    const {
      phase1: phaseAngle1 = -30.0,
      phase2: phaseAngle2 = 30.0,
      phase3: phaseAngle3 = 0.0,
    } = phaseCorrectionAngles || {}
    const { id: panelId } = panel || {}
    const voltageScalingFactorFormatted =
      formatNumberToDecimals(voltageScalingFactor)
    let selectedStepdownTransformer = null
    if (powerSource && powerSource.stepdownTransformer) {
      selectedStepdownTransformer = stepDownTransformers.find(
        (transformer) =>
          powerSource.stepdownTransformer.name === transformer.name,
      )
    }
    return {
      channels: channelListToMap(channelList),
      panelId,
      panel,
      voltageScalingFactor: voltageScalingFactorFormatted,
      voltageScalingFactorCustom: voltageScalingFactorFormatted,
      voltageScalingFactorL1: phase1,
      voltageScalingFactorL2: phase2,
      voltageScalingFactorL3: phase3,
      vtapL1PhaseA: valueOrDefault(vtapL1PhaseA, null),
      vtapL2PhaseB: valueOrDefault(vtapL2PhaseB, null),
      vtapL3PhaseC: valueOrDefault(vtapL3PhaseC, null),
      phaseCorrectionAngleL1: phaseAngle1,
      phaseCorrectionAngleL2: phaseAngle2,
      phaseCorrectionAngleL3: phaseAngle3,
      highLeg: powerSource ? powerSource.highLeg : null,
      stepDownTransformerName:
        selectedStepdownTransformer ? selectedStepdownTransformer.name : null,
    }
  },

  mapPropsToStatus({ goBack, meter, showConfirmModal }) {
    const { validCtTypes, isNebula, isOrion } = meter
    return {
      ctTypes: validCtTypes.map((pair) => makeListSelectorItem(...pair)),
      goBack,
      meter,
      phaseOptions,
      showConfirmModal,
      voltageTapPhases: getVoltageTapPhases(isNebula || isOrion),
      voltageTapOptions,
    }
  },
})(Form)
