import { FormikProps, withFormik } from 'formik'
import isEqual from 'lodash.isequal'
import { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'
import Button from '../Button'
import StyledCheckbox from '../Checkbox'
import FormField from '../FormField'
import FormFieldInteger from '../FormFieldInteger'
import StyledRadioButton from '../RadioButton'
import Spinner from '../Spinner'
import UnsavedChanges from '../UnsavedChanges'
import {
  TSEnergyStarCustomerStatus,
  TSEnergyStarEntityState,
} from '../../ducks/energyStarIntegration/types'

const FieldTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  margin-bottom: 16px;
`
const FormFieldWrapper = styled.div`
  width: 300px;
  .input__error {
    position: static;
  }
`
const RadioButtonTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  margin-bottom: 16px;
  margin-left: 8px;
  cursor: pointer;
`
const FieldValue = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
`
const FieldWrapper = styled.div`
  margin-bottom: 24px;
`
const RadioButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 30px;
`
const ButtonStyled = styled(Button)`
  margin-right: 20px;
  margin-top: 10px;
`
const Row = styled.div`
  display: flex;
`
export const staticTexts = {
  customerStatus: 'Customer Status',
  customerId: 'Energy Star Customer ID',
  monthlySubmissionReport: 'Send Monthly Submission Report to Customer',
  notificationEmail: 'Notification Email',
  energyStarCoordinator: 'Energy Star Coordinator(s)',
  enable: 'Enable',
  disable: 'Disable',
  cancel: 'Cancel',
  submit: 'Submit',
}

interface TSProps {
  energyStarEntity: TSEnergyStarEntityState
  onSubmit: (props: TSEnergyStarCustomerStatus) => void
}

const ESCustomerStatus = (
  props: TSProps &
    FormikProps<TSEnergyStarCustomerStatus> &
    RouteComponentProps,
) => {
  const {
    values,
    setFieldValue,
    handleReset,
    history,
    isValid,
    errors,
    touched,
    initialValues,
    energyStarEntity: {
      meta: { loading, error },
    },
    onSubmit,
    location: { pathname },
    match: { url },
  } = props
  const [isSubmitting, setIsSubmitting] = useState(false)

  const hasChanged = () => !isEqual(initialValues, values)

  const isEditMode = useMemo(
    () => pathname.split('/').reverse()[0] === 'edit',
    [pathname],
  )
  useEffect(() => {
    setIsSubmitting(false)
  }, [isEditMode])

  if (!isEditMode) {
    if (loading) {
      return <Spinner size='tiny' />
    }

    const energyStarCoordinators = values.energyStarCoordinator
      ?.split(',')
      .map((item) => item.trim())

    return (
      <>
        <FieldWrapper data-testid='customer-status'>
          <FieldTitle data-testid='field-title'>
            {staticTexts.customerStatus}
          </FieldTitle>
          <FieldValue data-testid='field-value'>
            {values.enabled ? 'Enabled' : 'Disabled'}
          </FieldValue>
        </FieldWrapper>
        <FieldWrapper data-testid='energy-star-id'>
          <FieldTitle data-testid='field-title'>
            {staticTexts.customerId}
          </FieldTitle>
          <FieldValue data-testid='field-value'>
            {values.energyStarId || 'N/A'}
          </FieldValue>
        </FieldWrapper>
        <Row data-testid='monthly-report'>
          <StyledCheckbox
            data-testid='checkbox'
            readOnly
            checked={values.sendMonthlyReport}
          />
          <FieldTitle data-testid='field-title'>
            {staticTexts.monthlySubmissionReport}
          </FieldTitle>
        </Row>
        <FieldWrapper data-testid='notification-email'>
          <FieldTitle data-testid='field-title'>
            {staticTexts.notificationEmail}
          </FieldTitle>
          <FieldValue data-testid='field-value'>
            {values.emailId || 'N/A'}
          </FieldValue>
        </FieldWrapper>
        <FieldWrapper data-testid='energy-star-coordinator-email'>
          <FieldTitle data-testid='field-title'>
            {staticTexts.energyStarCoordinator}
          </FieldTitle>
          <FieldValue data-testid='field-value'>
            {!values.energyStarCoordinator && 'N/A'}
            {energyStarCoordinators?.length === 1 ?
              energyStarCoordinators?.[0]
            : energyStarCoordinators?.map((name) => <li>{name}</li>)}
          </FieldValue>
        </FieldWrapper>
      </>
    )
  }

  return (
    <>
      <UnsavedChanges when={!loading && hasChanged() && !isSubmitting} />
      <FieldWrapper data-testid='customer-status'>
        <FieldTitle data-testid='field-title'>
          {staticTexts.customerStatus}
        </FieldTitle>
        <RadioButtonWrapper
          data-testid='input-field-enable'
          onClick={() => !loading && setFieldValue('enabled', true)}
        >
          <StyledRadioButton
            disabled={loading}
            name='customerStatus'
            checked={values.enabled}
          />
          <RadioButtonTitle>{staticTexts.enable}</RadioButtonTitle>
        </RadioButtonWrapper>
        <RadioButtonWrapper
          data-testid='input-field-disable'
          onClick={() => !loading && setFieldValue('enabled', false)}
        >
          <StyledRadioButton
            disabled={loading}
            name='customerStatus'
            checked={!values.enabled}
          />
          <RadioButtonTitle>{staticTexts.disable}</RadioButtonTitle>
        </RadioButtonWrapper>
      </FieldWrapper>
      <FieldWrapper data-testid='energy-star-id'>
        <FieldTitle data-testid='field-title'>
          {staticTexts.customerId}
        </FieldTitle>
        <FormFieldWrapper>
          <FormFieldInteger
            data-testid='field-value'
            name='energyStarId'
            isReadOnly={loading}
            error={touched.energyStarId && (errors.energyStarId || error)}
          />
        </FormFieldWrapper>
      </FieldWrapper>
      <FieldWrapper data-testid='monthly-report'>
        <Row>
          <StyledCheckbox
            data-testid='field-value'
            checked={values.sendMonthlyReport}
            onChange={({ target: { checked } }) =>
              setFieldValue('sendMonthlyReport', checked)
            }
          />
          <FieldTitle data-testid='field-title'>
            {staticTexts.monthlySubmissionReport}
          </FieldTitle>
        </Row>
        <span data-testid='notification-email'>
          <FieldTitle data-testid='field-title'>
            {staticTexts.notificationEmail}
          </FieldTitle>
          <FormFieldWrapper>
            <FormField
              data-testid='field-value'
              name='emailId'
              isReadOnly={loading}
              error={
                touched.emailId && !!errors.emailId ? errors.emailId : undefined
              }
            />
          </FormFieldWrapper>
        </span>
      </FieldWrapper>
      <FieldWrapper>
        <ButtonStyled
          data-testid='cancel-button'
          disabled={loading}
          onClick={() => {
            handleReset()
            history.push(url)
          }}
        >
          {staticTexts.cancel}
        </ButtonStyled>
        <ButtonStyled
          loading={loading}
          data-testid='submit-button'
          disabled={!isValid || !hasChanged()}
          primary
          onClick={() => {
            setIsSubmitting(true)
            onSubmit(values)
          }}
        >
          {staticTexts.submit}
        </ButtonStyled>
      </FieldWrapper>
    </>
  )
}

const validationSchema = Yup.object().shape({
  enabled: Yup.boolean().required(),
  energyStarId: Yup.number().required(
    'Please enter a valid energy star customer ID',
  ),
  sendMonthlyReport: Yup.boolean().required(),
  emailId: Yup.string()
    .email('Please enter a valid email')
    .nullable()
    .when('sendMonthlyReport', {
      is: true,
      then: Yup.string().required('Email is required'),
      otherwise: Yup.string(),
    }),
})
export default withRouter(
  withFormik<TSProps & RouteComponentProps, TSEnergyStarCustomerStatus>({
    enableReinitialize: true,
    validationSchema,
    mapPropsToValues: ({ energyStarEntity: { customerStatus } }) => ({
      ...customerStatus,
    }),
    handleSubmit: () => {},
  })(ESCustomerStatus),
)
