/* eslint-disable react/jsx-props-no-spreading */
import type { ComponentType } from 'react'
import React, { useRef } from 'react'
import debounce from 'debounce'
import { ReactSortable } from 'react-sortablejs'
import styled, { css } from 'styled-components'
import {
  useAsyncDebounce,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useExpanded,
  useRowSelect,
  useTable,
  useMountedLayoutEffect,
  Column,
  UseFiltersColumnOptions,
} from 'react-table'
import Button from '../Button'
import MenuIcon from '../Icons/MenuIcon'
import SearchIcon from '../Icons/SearchIcon'
import useComponentToggle from '../../hooks/useComponentToggle'
import LockIcon from '../Icons/LockIcon'
import LockOpenIcon from '../Icons/LockOpenIcon'
import RemoveRedEyeIcon from '../Icons/RemoveRedEyeIcon'
import {
  getElementScrollXPosition,
  isElementWidthOverflowing,
  naturallySortEmptyLastCaseInsensitive,
  zIndices,
} from '../../utils'
import SettingsIcon from '../Icons/SettingsIcon'
import { colors } from '../../utils/themes'
import Checkbox from '../Checkbox'
import ListSelector, { SelectStyles } from '../ListSelector'
import Paginator2 from '../Paginator2'
import PageSizeSelector from '../EntityList/PageSizeSelector'

const columnMinWidthDefault = '140px'
export const tableBorder = '1px solid #e0e0e0'
export const RedaptiveReactTableStyles = styled.div``
export const InputFilterStyled = styled.input`
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  height: 28px;
  margin-top: 8px;
  padding-left: 30px !important;
  width: 100%;
`
export const SearchIconStyled = styled(SearchIcon)`
  color: #6c6d6e;
  margin-top: 2px;
  min-width: 40px;
  padding: 8px;
  position: absolute;
`
export const InputWrapperStyled = styled.div`
  margin-bottom: 10px;
  padding-right: 10px;
  position: relative;
  width: 100%;
`
export const ReactTableTopSectionStyles: ComponentType<{
  sideMarginWidth: number
}> = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 ${({ sideMarginWidth }) => sideMarginWidth}px 16px;

  > * {
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }
  }
`
export const GlobalFilterWrapper = styled.div`
  position: relative;
  width: 160px;
`
const GlobalFilterSearchIcon = styled(SearchIcon)`
  color: ${colors.blue3};
  font-size: 24px;
  position: absolute;
  left: 7px;
  top: 7px;
`
export const GlobalFilterInput = styled.input`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  height: 36px;
  padding: 8px 8px 8px 34px;
  width: 100%;
`
const ColumnSettingsStyles = styled.div`
  position: relative;
`
const SettingsIconStyled = styled(SettingsIcon)`
  font-size: 22px;
  margin-right: 3px;
`
const ColumnSettingsButtonStyled: ComponentType<{
  columnSettingsListVisible: boolean
}> = styled(Button)`
  align-items: center;
  background-color: ${({ columnSettingsListVisible }) =>
    columnSettingsListVisible ? colors.blue2 : '#fff'};
  color: ${({ columnSettingsListVisible }) =>
    columnSettingsListVisible ? '#fff' : colors.blue2};
  display: flex;
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  letter-spacing: normal;
  padding: 0 16px;
  text-transform: none;
  z-index: ${zIndices.RedaptiveReactTableColumnSettingsList};
`
export const ColumnSettingsListStyles: ComponentType<{
  columnSettingsListVisible: boolean
}> = styled.div`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dadce0;
  box-shadow: 0 0 10px 2px rgba(130, 130, 130, 0.25);
  display: ${({ columnSettingsListVisible }) =>
    columnSettingsListVisible ? 'block' : 'none'};
  max-height: 340px;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 40px;
  width: 240px;
  z-index: ${zIndices.RedaptiveReactTableColumnSettingsList};
`
export const ColumnSettingsListHeaderStyles = styled.div`
  align-items: center;
  background-color: #fafafa;
  border-bottom: 1px solid #e0e0e0;
  color: ${colors.blue2};
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: space-between;
  padding: 10px 14px 8px;
`
export const ColumnSettingsListItemStyled = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: space-between;
  padding: 5px 10px;

  &:first-child {
    padding-top: 10px;
  }
`
export const ColumnSettingsListItemLeftStyled = styled.div`
  align-items: center;
  display: flex;
`
export const ColumnSettingsListItemRightStyled = styled.div`
  align-items: center;
  display: flex;
`
const MenuIconLockedStyles = css`
  color: #ddd;
`
const MenuIconUnlockedStyles = css`
  cursor: move;
`
export const MenuIconStyled: ComponentType<{
  locked: boolean
}> = styled(MenuIcon)`
  ${({ locked }) => (locked && MenuIconLockedStyles) || MenuIconUnlockedStyles};
  font-size: 18px;
  margin-right: 6px;
  position: relative;
  top: -1px;
`
export const LockIconStyled = styled(LockIcon)`
  color: ${colors.blue2};
  font-size: 18px;
`
export const LockIconSmallStyled = styled(LockIcon)`
  color: ${colors.blue2};
  font-size: 14px;
  position: relative;
  top: 1px;
`
export const VisibleEyeIconStyled = styled(RemoveRedEyeIcon)`
  color: ${colors.blue2};
  cursor: pointer;
  font-size: 18px;
  position: relative;
  top: 1px;
`
export const NoVisibleEyeIconStyled = styled(RemoveRedEyeIcon)`
  color: #ddd;
  cursor: pointer;
  font-size: 18px;
  position: relative;
  top: 1px;
`
export const LockOpenIconStyled = styled(LockOpenIcon)`
  color: #a7a7a8;
  font-size: 18px;
`
export const ReactTableWrapperStyles = styled.div<{ fixedHeader?: boolean }>`
  border-top: ${tableBorder};
  ${({ fixedHeader }) =>
    fixedHeader &&
    css`
      height: ${window.innerHeight - 220}px;
    `}
`
export const ReactTableStyled = styled.table`
  border-collapse: collapse;
  overflow-wrap: break-word;
  width: 100%;
`
export const TableHeadStyled: ComponentType<{
  fixedHeader?: boolean
}> = styled.thead`
  border-bottom: ${tableBorder};
  ${({ fixedHeader }) =>
    fixedHeader &&
    css`
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2;
    `}
`
const ThLockedStyles = css`
  position: sticky;
  z-index: ${zIndices.RedaptiveReactTableThLocked};
`
const ThInnerAlignStyles: ComponentType<{
  align?: 'left' | 'right' | 'center'
}> = css`
  display: flex;
  justify-content: ${({ align = 'left' }) => align};
`
export const ThInnerStyled: ComponentType<{
  align?: 'left' | 'right' | 'center'
  columnMaxWidth: number
  columnMinWidth: number
}> = styled.div`
  ${({ align }) => align && ThInnerAlignStyles};
  max-width: ${({ columnMaxWidth }) =>
    columnMaxWidth ? `${columnMaxWidth}px` : 'none'};
  min-width: ${({ columnMinWidth }) =>
    columnMinWidth ? `${columnMinWidth}px` : columnMinWidthDefault};
`
const ThSelectAllStyles = css`
  vertical-align: bottom;

  ${ThInnerStyled} {
    position: relative;
    bottom: 9px;
  }
`
export const ThStyled: ComponentType<{
  left: number
  locked: boolean
  sideMarginWidth: number
  fixActionsToRight: boolean
  fixedHeader: boolean
}> = styled.th`
  ${({ locked }) => locked && ThLockedStyles};
  background-color: #fafafa;
  font-size: 12px;
  font-weight: 600;
  padding: 3px 0 2px 0;
  text-align: left;
  vertical-align: top;
  left: ${({ left }) => (left ? `${left}px` : '0px')};
  position: ${({ locked, left }) =>
    locked || left === undefined ? 'sticky' : 'static'};
  z-index: ${({ locked, left }) => (locked || left === undefined ? 1 : 0)};
  ${({ fixActionsToRight }) =>
    fixActionsToRight &&
    css`
      right: ${({ left }) => (left === undefined ? '0px' : 'auto')};
    `}
  ${({ id }) => id === 'selectAll' && ThSelectAllStyles};

  &:first-child {
    > ${ThInnerStyled} {
      padding: 0 ${({ sideMarginWidth }) => sideMarginWidth}px;
      ${({ fixedHeader }) =>
        fixedHeader &&
        css`
          height: ${window.innerHeight - 220}px;
        `}
    }
  }

  &:last-child {
    > ${ThInnerStyled} {
      padding-right: ${({ sideMarginWidth }) => sideMarginWidth}px;
      right: 0px;
      position: sticky;
    }
  }
`
const TableBodyTrStyled = styled.tr`
  position: relative;

  td {
  }

  &:hover {
    td {
      background-color: #f0f2f8;
    }
  }

  &:last-child {
    border-bottom: 0;
  }
  ${({ alphaLayerOpen }) =>
    alphaLayerOpen &&
    css`
      :focus-within {
        z-index: ${zIndices.RedaptiveReactTableActiveRow};
      }
    `}
`
const TrHighlightStyled: ComponentType<{
  highlightColor?: string
}> = styled.div`
  background-color: ${({ highlightColor }) => highlightColor || 'transparent'};
  display: ${({ highlightColor }) => !highlightColor && 'none'};
  height: 100%;
  top: 0px;
  position: absolute;
  width: 8px;
`
export const TdInnerStyled: ComponentType<{
  columnMaxWidth: number
  columnMinWidth: number
}> = styled.div`
  font-weight: 300;
  display: flex;
  align-items: center;
  padding: 10px 30px 10px 0;
  max-width: ${({ columnMaxWidth }) =>
    columnMaxWidth ? `${columnMaxWidth}px` : 'none'};
  min-width: ${({ columnMinWidth }) =>
    columnMinWidth ? `${columnMinWidth}px` : columnMinWidthDefault};
`
export const TdStyled: ComponentType<{
  left: number
  index: string
  locked: boolean
  sideMarginWidth: number
  fixActionsToRight: boolean
}> = styled.td`
  background-color: #fff;
  border-bottom: ${tableBorder};
  left: ${({ left }) => (left ? `${left}px` : '0px')};
  position: ${({ locked, left }) =>
    locked || left === undefined ? 'sticky' : 'static'};
  z-index: ${({ locked, left }) => (locked || left === undefined ? 1 : 0)};
  ${({ fixActionsToRight }) =>
    fixActionsToRight &&
    css`
      right: ${({ left }) => (left === undefined ? '0px' : 'auto')};
    `}
  &:first-child {
    > ${TdInnerStyled} {
      padding-left: ${({ sideMarginWidth }) => sideMarginWidth}px;
    }
  }

  &:last-child {
    > ${TdInnerStyled} {
      padding-right: ${({ sideMarginWidth }) => sideMarginWidth}px;
    }
  }
  text-align: left;
`
export const HeaderStyled = styled.div`
  align-items: center;
  color: ${colors.blue3};
  display: flex;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.02em;
  min-height: 30px;
`
export const HeaderActionsWrapper = styled.div``
export const SortIconsWrapperStyled = styled.div`
  display: inline-block;
  margin: 0 6px;
  position: relative;
  top: -1px;
  width: 13px;
`
export const SortIconsStyled = styled.div`
  line-height: 1;

  > * {
    display: block;
  }
`
export const SortIconDesc: ComponentType<{
  sorted?: boolean
}> = styled.span.attrs({
  className: 'ion-ios-arrow-down',
})`
  position: relative;
  top: ${({ sorted }) => (sorted ? '7px' : '0')};
`
export const SortIconAsc = styled.span.attrs({
  className: 'ion-ios-arrow-up',
})`
  position: relative;
  top: ${({ sorted }) => (sorted ? '-3px' : '5px')};
`
export const TableScrollStyled = styled.div`
  display: flex;
  align-items: center;
`
const TableScrollArrowsSeparator = styled.div`
  background-color: #e0e0e0;
  height: 20px;
  width: 1px;
  margin: 0 12px;
`
const TableScrollArrowsStyled = styled.div``
const TableScrollArrowStyled: ComponentType<{
  disabled?: boolean
}> = styled.div`
  background-color: ${({ disabled }) => (disabled ? '#fafafa' : '#f5f5f5')};
  border: 1px solid ${({ disabled }) => (disabled ? '#efefef' : '#e0e0e0')};
  border-radius: 4px;
  color: ${({ disabled }) => (disabled ? '#B5B6B6' : '#162447')};
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  padding: 2px 10px;

  :first-child {
    margin-right: 8px;
  }
`
const PaginatorWrapperStyled = styled.div`
  padding: 30px 30px;
`

const Paginator2Styled = styled.div`
  margin: 20px 0;
  position: relative;
  bottom: 50px;
`

export type TSColumnType<T> = Partial<
  Column<T> & UseFiltersColumnOptions<T>
> & { hideSettings?: boolean }

type FTProps = {
  alwaysLockedColumns?: Array<string>
  autoResetExpanded?: boolean
  columns: Array<Record<string, any>>
  data: Array<Record<string, any>>
  defaultSort?: Array<{
    id: string
    desc?: boolean
  }>
  enableColumnHiding?: boolean
  enableColumnSettings?: boolean
  enablePagination?: boolean
  enableRowSelection?: boolean
  fixActionsToRight?: boolean
  filterable?: boolean
  getRowProps?: (arg0: Record<string, any>) => Record<string, any>
  getCellProps?: (arg0: Record<string, any>) => Record<string, any>
  globalFilterable?: boolean
  handleColumnSettingsChange?: (arg0: {
    columns: Array<string>
    lockedColumns: Array<string>
    columnsVisibleOnTable?: Array<string>
  }) => any
  handleColumnSettingsClose?: (arg0: {
    columns: Array<string>
    lockedColumns: Array<string>
    columnsVisibleOnTable: Array<string>
  }) => any
  HeaderActions?: React.ComponentType<any>
  hideHeaders?: boolean
  initialHiddenColumns?: Array<string>
  initialLockedColumns?: Array<string>
  initialPageSize?: number
  maxLockableColumns?: number
  onSelectedRowsChange?: (...args: Array<any>) => any
  selectedRows?: Record<string, any>
  setHiddenColumns?: (...args: Array<any>) => any
  getSelectedRowsData?: (...args: Array<any>) => any
  showTableScrollArrows?: boolean
  sideMarginWidth?: number
  TableHead?: React.ComponentType<any>
  tableInstanceRef?: {
    current: any
  }
  TdComponent?: React.ComponentType<any>
  alphaLayerOpen?: boolean
  fixedHeader?: boolean
  onSortChange?: (...args: Array<any>) => any
  onPageParamsChange?: (...args: Array<any>) => any
  customTotalPages?: number
  customPageIndex?: number
  customPageSize?: number
  defaultTableTextNode?: any
  hideDropdownPagination?: boolean
  showLinearPagination?: boolean
  hideSeparator?: boolean
}
type FTColumnOrderItems = Array<{
  id: string
  name: string
}>

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
}: Record<string, any>) => {
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((inputValue) => {
    setGlobalFilter(inputValue || undefined)
  }, 200)
  return (
    <GlobalFilterWrapper>
      <GlobalFilterSearchIcon />
      <GlobalFilterInput
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder='Search'
      />
    </GlobalFilterWrapper>
  )
}

export const DefaultColumnFilter = ({
  column: { filterValue, setFilter },
}: {
  column: {
    filterValue: string
    setFilter: (...args: Array<any>) => any
  }
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  return (
    <InputWrapperStyled>
      <SearchIconStyled
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.focus()
          }
        }}
      />
      <InputFilterStyled
        ref={inputRef}
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    </InputWrapperStyled>
  )
}
export const SelectColumnMultiFilterListSelectorStyled = styled(ListSelector)`
  margin: 8px 0 10px;

  ${SelectStyles} {
    height: auto;
    max-width: 250px;
    margin-right: 10px;

    .Select {
      height: auto;
    }

    .Select__control {
      border: 1px solid #e0e0e0;
      border-radius: 2px;
      min-height: 28px;
    }
  }
`
export const ColumnSettingHidingItemStyled = styled.div`
  position: relative;

  > input {
    position: absolute;
    opacity: 0;
    right: -1px;
  }
`
const ColumnSettingsFullScreenBgAlphaLayer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff5c;
  display: ${({ columnSettingsListVisible }) =>
    columnSettingsListVisible ? 'block' : 'none'};
  z-index: ${zIndices.RedaptiveReactTableColumnSettingsList};
`
export const SelectColumnMultiFilter = ({
  column: { filterValue = [], setFilter, preFilteredRows, id },
}: any) => {
  const items = React.useMemo(
    () =>
      [...new Set(preFilteredRows.map((row) => row.values[id]))].map(
        (value) => ({
          id: value,
          name: value,
        }),
      ),
    [id, preFilteredRows],
  )
  return (
    <SelectColumnMultiFilterListSelectorStyled
      isMulti
      items={items}
      unsettable={false}
      updateValueMulti={(newValues) => {
        setFilter(newValues.map((newValue) => newValue.value))
      }}
      selectedItems={items.filter((item) => filterValue.includes(item.id))}
    />
  )
}
const defaultMaxLockableColumns = 4
const defaultSideMarginWidth = 32
export default ({
  alwaysLockedColumns = [],
  autoResetExpanded = true,
  columns,
  data,
  defaultSort = [],
  enableColumnHiding = false,
  enableColumnSettings = false,
  enablePagination = false,
  enableRowSelection = false,
  fixActionsToRight = false,
  filterable = false,
  getRowProps = () => {},
  getCellProps = () => {},
  globalFilterable = true,
  handleColumnSettingsChange = () => null,
  handleColumnSettingsClose,
  HeaderActions = () => '',
  hideHeaders = false,
  initialHiddenColumns = [],
  initialLockedColumns = [],
  initialPageSize = 10,
  maxLockableColumns = defaultMaxLockableColumns,
  onSelectedRowsChange = () => {},
  selectedRows = {},
  setHiddenColumns = () => {},
  showTableScrollArrows = true,
  sideMarginWidth = defaultSideMarginWidth,
  TableHead,
  tableInstanceRef,
  TdComponent = TdStyled,
  alphaLayerOpen = false,
  fixedHeader = false,
  onSortChange,
  onPageParamsChange,
  customTotalPages,
  customPageIndex,
  customPageSize,
  getSelectedRowsData = () => {},
  defaultTableTextNode,
  hideDropdownPagination = false,
  showLinearPagination = false,
  hideSeparator = false,
}: FTProps) => {
  const columnSettingsListRef = React.useRef(null)
  const columnSettingsButtonRef = React.useRef(null)
  const TableWrapperRef = React.useRef(null)
  const isManualPagination: Record<string, any> =
    customTotalPages !== undefined &&
    customPageIndex !== undefined &&
    customPageSize !== undefined
  const { isComponentVisible: columnSettingsListVisible } = useComponentToggle({
    componentRef: columnSettingsListRef,
    initialVisible: false,
    toggleRef: columnSettingsButtonRef,
  })
  const [lockedColumns, setLockedColumns] = React.useState([
    ...new Set(initialLockedColumns.concat(alwaysLockedColumns)),
  ])
  const [columnsHaveChanged, setColumnsHaveChanged] = React.useState(false)
  const tableData = React.useMemo(() => data, [data])
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  )
  const initialSortBy = React.useMemo(() => defaultSort || [], [])
  const defaultSortType = React.useCallback(
    (
      rowA: Record<string, any>,
      rowB: Record<string, any>,
      columnId: string,
      desc: boolean,
    ) =>
      naturallySortEmptyLastCaseInsensitive(
        rowA.values[columnId],
        rowB.values[columnId],
        desc,
      ),
    [],
  )
  const columnsEnhanced = React.useMemo(
    () =>
      columns.map((column) => ({
        sortType: defaultSortType,
        ...column,
      })),
    [columns],
  )
  const tableInstance = useTable(
    {
      autoResetExpanded,
      columns: columnsEnhanced,
      data: tableData,
      defaultColumn,
      initialState: {
        hiddenColumns: initialHiddenColumns,
        pageSize: enablePagination ? initialPageSize : 100000,
        sortBy: initialSortBy,
        selectedRowIds: selectedRows,
        ...(isManualPagination && {
          pageIndex: customPageIndex,
          pageSize: customPageSize,
        }),
      },
      ...(isManualPagination && {
        manualPagination: true,
        pageCount: customTotalPages,
      }),
      paginateExpandedRows: false,
    },
    useColumnOrder,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (enableRowSelection) {
        hooks.visibleColumns.push((existingColumns: Record<string, any>) => [
          {
            id: 'selectAll',
            Header: ({
              getToggleAllRowsSelectedProps,
            }: Record<string, any>) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }: Record<string, any>) => (
              <div>
                <Checkbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
            minWidth: 50,
            hideSettings: true,
          },
          ...existingColumns,
        ])
      }
    },
    setHiddenColumns,
  )
  const {
    allColumns,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    preGlobalFilteredRows,
    prepareRow,
    setColumnOrder,
    setGlobalFilter,
    state,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    selectedFlatRows,
  } = tableInstance
  const { pageIndex, pageSize, sortBy } = state
  React.useEffect(() => {
    if (tableInstanceRef) {
      // eslint-disable-next-line no-param-reassign
      tableInstanceRef.current = tableInstance
    }
  }, [tableInstance])
  React.useEffect(() => {
    if (onSortChange) onSortChange(sortBy)
  }, [sortBy])
  React.useEffect(() => {
    if (onPageParamsChange)
      onPageParamsChange({
        pageIndex,
        pageSize,
      })
  }, [pageIndex, pageSize])
  const [reactSortableColumns, setReactSortableColumns]: [
    FTColumnOrderItems,
    (...args: Array<any>) => any,
  ] = React.useState(
    allColumns
      .filter((column) => !column.hideSettings)
      .map((column) => ({
        id: column.id,
        name: column.render('Header'),
      })),
  )
  React.useEffect(() => {
    setReactSortableColumns(
      allColumns
        .filter((column) => !column.hideSettings)
        .map((column) => ({
          id: column.id,
          name: column.render('Header'),
        })),
    )
  }, [allColumns])

  /**
   * This state variable ensures that handleColumnSettingsClose doesn't trigger infinite rendering upon initial page
   * load or after closing the column settings
   * False initially. Later on, it follows the value of columnSettingsListVisible.
   */
  const [columnSettingsOpened, setColumnSettingsOpened] = React.useState(false)
  const handleSortableColumnsChange = React.useCallback(
    (items: FTColumnOrderItems) => {
      setReactSortableColumns(items)
      setColumnOrder(items.map(({ id }) => id))
    },
    [],
  )
  const setNewSortableColumnsFromLockedColumns = React.useCallback(
    (newLockedColumns) => {
      let newSortableColumns = []
      reactSortableColumns.forEach((item) => {
        if (newLockedColumns.includes(item.id)) {
          newSortableColumns.push(item)
        }
      })
      newSortableColumns = newSortableColumns.concat(
        reactSortableColumns.filter(
          (item) => !newLockedColumns.includes(item.id),
        ),
      )
      handleSortableColumnsChange(newSortableColumns)
    },
    [reactSortableColumns],
  )
  const tableCellRefs = React.useMemo(
    () =>
      allColumns.reduce(
        (acc, { id }) => ({ ...acc, [id]: React.createRef() }),
        {},
      ),
    [],
  )
  const getColumnsLeftOffset = React.useCallback(() => {
    let previousLeftOffset =
      enableRowSelection ?
        tableCellRefs?.selectAll?.current?.clientWidth ?? 89
      : 0
    let columnsOffset = reactSortableColumns.reduce((acc, { id }) => {
      const clientWidth =
        (tableCellRefs[id] &&
          tableCellRefs[id].current &&
          tableCellRefs[id].current.clientWidth) ||
        150
      const leftOffset = previousLeftOffset
      previousLeftOffset += clientWidth
      return { ...acc, [id]: leftOffset }
    }, {})
    columnsOffset =
      enableRowSelection ? { ...columnsOffset, selectAll: 0 } : columnsOffset
    return columnsOffset
  }, [reactSortableColumns, tableCellRefs])
  const [columnsLeftOffset, setColumnsLeftOffset] = React.useState(
    getColumnsLeftOffset(),
  )
  const handleLockClosedClick = React.useCallback(
    ({ target }: React.SyntheticEvent) => {
      const { id = '' } = target || {}
      const fieldName = id.replace('Lock', '')

      if (!alwaysLockedColumns.includes(fieldName)) {
        const newLockedColumns = lockedColumns.filter(
          (columnName) => columnName !== fieldName,
        )
        setLockedColumns(newLockedColumns)
        setNewSortableColumnsFromLockedColumns(newLockedColumns)
      }
    },
    [lockedColumns],
  )
  const handleLockOpenClick = React.useCallback(
    ({ target }: React.SyntheticEvent) => {
      if (lockedColumns.length === maxLockableColumns) {
        return
      }

      const { id = '' } = target || {}
      const fieldName = id.replace('LockOpen', '')
      const newLockedColumns = [...lockedColumns, fieldName]
      setLockedColumns(newLockedColumns)
      setNewSortableColumnsFromLockedColumns(newLockedColumns)
    },
    [lockedColumns],
  )
  React.useEffect(() => {
    if (handleColumnSettingsChange) {
      handleColumnSettingsChange({
        columns: reactSortableColumns.map((item) => item.id),
        lockedColumns,
        columnsVisibleOnTable: visibleColumns.map((item) => item.id),
      })
    }

    setColumnsHaveChanged(true)
  }, [reactSortableColumns, lockedColumns])
  React.useEffect(() => {
    if (!columnSettingsOpened && columnSettingsListVisible) {
      setColumnSettingsOpened(true)
    }

    // columnSettingsOpened is reverted to false, after closing the column settings.
    if (columnSettingsOpened && !columnSettingsListVisible) {
      setColumnSettingsOpened(false)
    }
  }, [columnSettingsOpened, columnSettingsListVisible])
  React.useEffect(() => {
    if (
      handleColumnSettingsClose &&
      !columnSettingsListVisible &&
      columnSettingsOpened
    ) {
      handleColumnSettingsClose({
        columns: reactSortableColumns.map((item) => item.id),
        lockedColumns,
        columnsVisibleOnTable: visibleColumns.map((item) => item.id),
      })
    }
  }, [
    reactSortableColumns,
    lockedColumns,
    columnSettingsListVisible,
    columnSettingsOpened,
    visibleColumns,
  ])
  const getTableScrollPosition = React.useCallback(
    () => getElementScrollXPosition(TableWrapperRef.current),
    [],
  )
  const [tableScrollPosition, setTableScrollPosition] = React.useState(
    getTableScrollPosition(),
  )
  const tableScroll = React.useCallback(() => {
    setTableScrollPosition(getTableScrollPosition())
  }, [])
  const handleTableScroll = React.useMemo(() => debounce(tableScroll, 100), [])
  React.useEffect(() => {
    setColumnsHaveChanged(true)
  }, [visibleColumns])
  React.useEffect(() => {
    if (TableWrapperRef.current) {
      setTableScrollPosition(getTableScrollPosition())
    }
  }, [TableWrapperRef])
  const getTableWidthIsOverflowing = React.useCallback(
    (): boolean | null | undefined =>
      TableWrapperRef.current &&
      isElementWidthOverflowing(TableWrapperRef.current),
    [],
  )
  const [tableWidthIsOverflowing, setTableWidthIsOverflowing] = React.useState(
    getTableWidthIsOverflowing(),
  )
  React.useEffect(() => {
    setTableWidthIsOverflowing(getTableWidthIsOverflowing())
  })
  React.useEffect(() => {
    setColumnsHaveChanged(true)
  }, [visibleColumns])
  const resizeEventAdded = React.useRef(false)

  if (!resizeEventAdded.current) {
    const resize = () => {
      setColumnsHaveChanged(true)
      setTableWidthIsOverflowing(getTableWidthIsOverflowing())
      tableScroll()
    }

    const handleResize = debounce(resize, 100)
    resizeEventAdded.current = true
    window.addEventListener('resize', handleResize)
  }

  React.useEffect(() => {
    if (columnsHaveChanged) {
      setColumnsLeftOffset(getColumnsLeftOffset())
      setColumnsHaveChanged(false)
    }
  }, [columnsHaveChanged])
  const handleTableScrollArrowClick = React.useCallback(
    ({ direction, disabled }: { direction: string; disabled: boolean }) =>
      () => {
        if (disabled || !TableWrapperRef.current) {
          return
        }

        const left = direction === 'left' ? -200 : 200
        TableWrapperRef.current.scrollBy({
          top: 0,
          left,
          behavior: 'smooth',
        })
      },
    [],
  )
  // Keep parent/store state in sync with local state
  // No need to update on mount since we are passing initial state
  useMountedLayoutEffect(() => {
    const { selectedRowIds } = state
    return onSelectedRowsChange && onSelectedRowsChange(selectedRowIds)
  }, [onSelectedRowsChange, state.selectedRowIds])
  React.useEffect(() => {
    if (getSelectedRowsData) getSelectedRowsData(selectedFlatRows)
  }, [selectedFlatRows])
  React.useEffect(() => {
    const { hiddenColumns } = state
    setHiddenColumns(hiddenColumns)
  }, [allColumns])
  const allColumnsById = React.useMemo(
    () => allColumns.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
    [allColumns],
  )
  return (
    <RedaptiveReactTableStyles>
      {showLinearPagination && (
        <Paginator2Styled>
          <PageSizeSelector
            paginationSize={[48, 20]}
            onUpdate={(value: string) => setPageSize(Number(value))}
            total={data?.length}
            currentSize={pageSize}
          />
        </Paginator2Styled>
      )}
      <ReactTableTopSectionStyles sideMarginWidth={sideMarginWidth}>
        {globalFilterable && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        <HeaderActionsWrapper>
          {HeaderActions && <HeaderActions />}
        </HeaderActionsWrapper>
        {enableColumnSettings && (
          <ColumnSettingsStyles>
            <ColumnSettingsFullScreenBgAlphaLayer
              columnSettingsListVisible={columnSettingsListVisible}
              ref={columnSettingsListRef}
            />
            <ColumnSettingsButtonStyled
              columnSettingsListVisible={columnSettingsListVisible}
              ref={columnSettingsButtonRef}
              disabled={alphaLayerOpen}
            >
              <SettingsIconStyled />
              Column Settings
            </ColumnSettingsButtonStyled>
            <ColumnSettingsListStyles
              columnSettingsListVisible={columnSettingsListVisible}
              ref={columnSettingsListRef}
            >
              <ColumnSettingsListHeaderStyles>
                <div>Column Settings</div>
                <div>
                  {maxLockableColumns - lockedColumns.length}
                  <LockIconSmallStyled />
                  left
                </div>
              </ColumnSettingsListHeaderStyles>
              <ReactSortable
                animation='150'
                draggable='.js-column-sort--draggable'
                handle='.js-column-sort--handle'
                list={reactSortableColumns}
                setList={handleSortableColumnsChange}
              >
                {reactSortableColumns.map((column) => (
                  <ColumnSettingsListItemStyled
                    key={column.id}
                    className={
                      (!lockedColumns.includes(column.id) &&
                        `js-column-sort--draggable ${
                          allColumnsById[column.id].isVisible ?
                            'column-visible'
                          : 'column-hidden'
                        }`) ||
                      'column-lock'
                    }
                  >
                    <ColumnSettingsListItemLeftStyled>
                      <MenuIconStyled
                        className='js-column-sort--handle'
                        locked={lockedColumns.includes(column.id)}
                      />
                      {column.name}
                    </ColumnSettingsListItemLeftStyled>
                    <ColumnSettingsListItemRightStyled>
                      {enableColumnHiding &&
                        !lockedColumns.includes(column.id) &&
                        allColumnsById[column.id] && (
                          <ColumnSettingHidingItemStyled>
                            {allColumnsById[column.id].isVisible ?
                              <VisibleEyeIconStyled />
                            : <NoVisibleEyeIconStyled />}
                            <input
                              type='checkbox'
                              {...allColumnsById[
                                column.id
                              ].getToggleHiddenProps()}
                            />
                          </ColumnSettingHidingItemStyled>
                        )}
                      {lockedColumns.includes(column.id) ?
                        <LockIconStyled
                          onClick={handleLockClosedClick}
                          id={`${column.id}Lock`}
                        />
                      : <LockOpenIconStyled
                          onClick={handleLockOpenClick}
                          id={`${column.id}LockOpen`}
                        />
                      }
                    </ColumnSettingsListItemRightStyled>
                  </ColumnSettingsListItemStyled>
                ))}
              </ReactSortable>
            </ColumnSettingsListStyles>
          </ColumnSettingsStyles>
        )}
        {showTableScrollArrows && tableWidthIsOverflowing && (
          <TableScrollStyled>
            {hideSeparator || <TableScrollArrowsSeparator />}
            <TableScrollArrowsStyled>
              <TableScrollArrowStyled
                className='ion-ios-arrow-left'
                disabled={tableScrollPosition.left === 0}
                onClick={handleTableScrollArrowClick({
                  disabled: tableScrollPosition.left === 0,
                  direction: 'left',
                })}
              />
              <TableScrollArrowStyled
                className='ion-ios-arrow-right'
                disabled={tableScrollPosition.right <= 0}
                onClick={handleTableScrollArrowClick({
                  disabled: tableScrollPosition.right <= 0,
                  direction: 'right',
                })}
              />
            </TableScrollArrowsStyled>
          </TableScrollStyled>
        )}
      </ReactTableTopSectionStyles>
      <ReactTableWrapperStyles
        ref={TableWrapperRef}
        onScroll={handleTableScroll}
        fixedHeader={fixedHeader}
      >
        <ReactTableStyled {...getTableProps()}>
          {TableHead && <TableHead />}
          {!hideHeaders && !TableHead && (
            <TableHeadStyled fixedHeader={fixedHeader}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <ThStyled
                      id={column.id}
                      left={columnsLeftOffset[column.id]}
                      locked={lockedColumns.includes(column.id)}
                      fixActionsToRight={fixActionsToRight}
                      sideMarginWidth={sideMarginWidth}
                      {...column.getHeaderProps()}
                    >
                      <ThInnerStyled
                        columnMaxWidth={column.maxWidth}
                        columnMinWidth={column.minWidth}
                      >
                        {column.hideHeader ? null : (
                          <>
                            <HeaderStyled {...column.getSortByToggleProps()}>
                              {column.render('Header')}
                              {column.canSort && (
                                <SortIconsWrapperStyled>
                                  <SortIconsStyled>
                                    {!column.isSorted && (
                                      <>
                                        <SortIconAsc />
                                        <SortIconDesc />
                                      </>
                                    )}
                                    {column.isSorted && (
                                      <>
                                        {column.isSortedDesc && (
                                          <SortIconDesc
                                            sorted={column.isSorted}
                                          />
                                        )}
                                        {!column.isSortedDesc && (
                                          <SortIconAsc
                                            sorted={column.isSorted}
                                          />
                                        )}
                                      </>
                                    )}
                                  </SortIconsStyled>
                                </SortIconsWrapperStyled>
                              )}
                            </HeaderStyled>
                            {filterable && (
                              <div>
                                {column.canFilter ?
                                  column.render('Filter')
                                : null}
                              </div>
                            )}
                          </>
                        )}
                      </ThInnerStyled>
                    </ThStyled>
                  ))}
                </tr>
              ))}
            </TableHeadStyled>
          )}
          <tbody {...getTableBodyProps()}>
            {defaultTableTextNode ||
              page.map((row) => {
                prepareRow(row)
                return (
                  <TableBodyTrStyled
                    {...row.getRowProps(getRowProps(row))}
                    tabIndex={row.index}
                    alphaLayerOpen
                  >
                    {row.cells.map((cell, index) => (
                      <TdComponent
                        row={row}
                        className={cell.column.id}
                        index={index}
                        left={columnsLeftOffset[cell.column.id]}
                        locked={lockedColumns.includes(cell.column.id)}
                        sideMarginWidth={sideMarginWidth}
                        ref={tableCellRefs[cell.column.id]}
                        fixActionsToRight={fixActionsToRight}
                        {...cell.getCellProps(getCellProps(cell))}
                      >
                        {index === 0 && (
                          <TrHighlightStyled {...getRowProps(row)} />
                        )}
                        <TdInnerStyled
                          columnMaxWidth={cell.column.maxWidth}
                          columnMinWidth={cell.column.minWidth}
                        >
                          {cell.render('Cell')}
                        </TdInnerStyled>
                      </TdComponent>
                    ))}
                  </TableBodyTrStyled>
                )
              })}
          </tbody>
        </ReactTableStyled>
      </ReactTableWrapperStyles>
      {enablePagination && !defaultTableTextNode && (
        <PaginatorWrapperStyled>
          <Paginator2
            pageSize={pageSize}
            gotoPage={gotoPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            setPageSize={setPageSize}
            hideDropdownPagination={hideDropdownPagination}
          />
        </PaginatorWrapperStyled>
      )}
    </RedaptiveReactTableStyles>
  )
}
